import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomSwiper } from '@utils/CustomSwiper';
import { SwiperSlide } from 'swiper/react';
import BaseModal from './BaseModal';
import Time from '@components/Time';
import RatingRange from '@components/RatingRange';
import { Switch } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

export const TIME_CONTROL_OPTIONS = [1, 3, 5, 10, 15, 30];

const MIN_RATING = 800;
const MAX_RATING = 3000;

export const MAX_REWARD = 10000;
export const MIN_REWARD = 0;

export const COMMISSION_MULTIPLIER = 1.2;
export const COMMISSION_DIVIDER = 2;

export const calculateCommission = (reward: number): number => {
  return (reward * COMMISSION_MULTIPLIER) / COMMISSION_DIVIDER;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ChallengeModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const [minRating, setMinRating] = useState<number>(MIN_RATING);
  const [maxRating, setMaxRating] = useState<number>(MAX_RATING);
  const [reward, setReward] = useState<number>(0);
  const [commission, setCommission] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);

  const [isRatingRangeEnabled, setRatingRangeEnabled] = useState<boolean>(false);
  const [isRewardEnabled, setRewardEnabled] = useState<boolean>(false);

  useEffect(() => {
    setCommission(calculateCommission(reward));
  }, [reward]);

  const handleCreateChallenge = () => {
    console.log('Challenge created:', {
      isRatingRangeEnabled,
      minRating,
      maxRating,
      reward,
      commission,
      selectedTime,
    });
    onClose();
  };

  const onSelectTime = (time: number) => {
    setSelectedTime(time);
  };

  const isFormValid =
    selectedTime &&
    (!isRewardEnabled || reward > MIN_REWARD) &&
    (!isRatingRangeEnabled || minRating < maxRating);

  const handleRatingRangeChange = (from: number, to: number) => {
    setMinRating(from);
    setMaxRating(to);
  };

  return (
    <BaseModal show={isOpen} setShow={onClose} title={t('home.challenge.title')}>
      <div className="challenge-modal-content">
        <h2 className="title">{t('home.challenge.time-control')}</h2>
        <CustomSwiper slidesPerView={3} spaceBetween={15} breakpoints={{}}>
          {TIME_CONTROL_OPTIONS.map((time) => (
            <SwiperSlide key={time}>
              <Time timeVariant={time} selectedTime={selectedTime} onSelectTime={onSelectTime} />
            </SwiperSlide>
          ))}
        </CustomSwiper>
        <div className="rating-range">
          <div className="rating-range-head">
            <h2 className="title">{t('home.challenge.rating-range')}</h2>
            <Switch
              className="custom-switch"
              checked={isRatingRangeEnabled}
              onChange={() => setRatingRangeEnabled(!isRatingRangeEnabled)}
              color="primary"
            />
          </div>
          <CSSTransition
            in={isRatingRangeEnabled}
            timeout={300}
            classNames="rating-transition"
            unmountOnExit>
            <div>
              <RatingRange
                minRating={MIN_RATING}
                maxRating={MAX_RATING}
                step={50}
                onChange={handleRatingRangeChange}
              />
            </div>
          </CSSTransition>
        </div>
        <div className="payout">
          <div className="payout-head">
            <div className="reward-toggle">
              <h2 className="title">{t('home.challenge.reward')}</h2>
              <Switch
                className="custom-switch"
                checked={isRewardEnabled}
                onChange={() => setRewardEnabled(!isRewardEnabled)}
              />
            </div>
            {isRewardEnabled && (
              <h2 className="commission-title title">{t('home.challenge.commission')}</h2>
            )}
          </div>
          <CSSTransition
            in={isRewardEnabled}
            timeout={300}
            className="payout-transition"
            unmountOnExit>
            <div>
              <div className="payout-body">
                <input
                  type="number"
                  className="form-control"
                  value={reward}
                  disabled={!isRewardEnabled}
                  onChange={(e) => setReward(Number(e.target.value))}
                  min="0"
                  max={MAX_REWARD}
                />
                <p className="amount">{commission.toFixed(2)}</p>
              </div>
            </div>
          </CSSTransition>
        </div>
        <button
          onClick={handleCreateChallenge}
          disabled={!isFormValid}
          type="button"
          className="btn btn-primary btn-block btn-lg">
          {t('home.challenge.create-btn')}
        </button>
      </div>
    </BaseModal>
  );
};

export default ChallengeModal;
